<template>
    <div>
        <vx-card title="Credit Note Promo Type">
            <open/>
        </vx-card>
    </div>
</template>
<script>
import open from "./open.vue";

export default {
    components: {
        open,
    },
}
</script>