var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex flex-col gap-6"},[_c('div',[_c('vs-button',{attrs:{"icon":"done","color":"primary"},on:{"click":function($event){return _vm.create()}}},[_vm._v(" Create Promo Type ")])],1),_c('div',{staticClass:"vx-row mb-12"},[_c('div',{class:[
        _vm.detail ? _vm.detailShow + ' vertical-divider' : '',
        _vm.detailHide,
      ]},[_c('data-table',{attrs:{"responseData":_vm.responseData,"propsParams":_vm.params,"header":_vm.header},on:{"reloadDataFromChild":_vm.reloadData}},[_c('template',{slot:"thead"}),_c('template',{slot:"tbody"},_vm._l((_vm.responseData.creditNotePromoType),function(tr,indextr){return _c('vs-tr',{key:indextr},[_c('vs-td',{staticClass:"whitespace-no-wrap"},[_c('feather-icon',{staticClass:"ml-2",attrs:{"title":"Edit","icon":"EditIcon","svgClasses":"w-5 h-5 hover:text-primary stroke-current"},on:{"click":function($event){$event.stopPropagation();return _vm.handleEdit(tr)}}}),_c('feather-icon',{staticClass:"ml-2",attrs:{"title":"Delete","icon":"TrashIcon","svgClasses":"w-5 h-5 hover:text-danger stroke-current"},on:{"click":function($event){$event.stopPropagation();return _vm.handleDelete(tr.ID)}}})],1),_c('vs-td',[_vm._v(" "+_vm._s(tr.Name)+" ")]),_c('vs-td',[_vm._v(" Promotion Fee Coa : "+_vm._s(tr.MappingGl[0].PromotionFeeCoa)+" "),_c('br'),_vm._v(" Withholding Tax Coa : "+_vm._s(tr.MappingGl[0].WithholdingTaxCoa)+" "),_c('br'),_vm._v(" Item Tax Code : "+_vm._s(tr.MappingGl[0].ItemTaxCode)+" "),_c('br')])],1)}),1)],2)],1),_c('transition',{attrs:{"name":"detail-fade"}},[(_vm.detail)?_c('div',{class:[
          _vm.detail ? ' nonfixed vx-col md:w-1/2 w-full mb-base' : '',
          _vm.detailHide,
        ]},[_c('div',[_c('vs-col',{staticStyle:{"padding-bottom":"8px"},attrs:{"vs-offset":"8","vs-type":"flex","vs-justify":"rigth","vs-align":"rigth","vs-w":"4"}},[_c('vs-button',{attrs:{"size":"small","color":"grey","icon-pack":"feather","icon":"icon-x-square"},on:{"click":_vm.closeDetail}},[_vm._v("Close")])],1),_c('create',{attrs:{"id":_vm.id,"data":_vm.selected,"url":_vm.baseUrl},on:{"closeDetail":_vm.closeDetail}})],1)]):_vm._e()])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }